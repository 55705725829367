const QuestionIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      className={className || 'w-4.5 h-4.5 fill-current'}
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g fill='currentColor' clipPath='url(#clip0_3506_2228)'>
        <path
          fillRule='evenodd'
          d='M3.525 4.857C5.016 3.014 7.351 1.818 10 1.818c2.649 0 4.984 1.196 6.475 3.04h2.212C16.958 1.946 13.701 0 10 0 6.3 0 3.042 1.947 1.313 4.857h2.212zm-.199 9.429H1.107c.28.487.616.942 1.005 1.372L.828 18.74a.91.91 0 00.962 1.25l5.303-.727a.909.909 0 10-.247-1.801l-3.205.44a.303.303 0 01-.32-.418l.94-2.256-.45-.43a7.128 7.128 0 01-.485-.513zM0 9.677v-.075a9.532 9.532 0 000 .075zm19.092 4.609H17.08c-1.127 1.79-3.24 2.935-6.886 3.045a.91.91 0 100 1.818c4.736 0 7.572-1.937 8.897-4.863z'
          clipRule='evenodd'
        ></path>
        <path d='M0 12.857V6.572h1.459v2.455h1.93V6.572h1.46v6.285h-1.46v-2.455H1.46v2.455H0zM5.984 12.857V6.572h3.872v1.375H7.443v1.08h2.214v1.375H7.443v1.08h2.403v1.375H5.984zM11.023 12.857V6.572h1.459v4.91h2.172v1.375h-3.63zM15.687 12.857V6.572h2.32c.398 0 .747.092 1.046.276.299.184.532.443.698.776.166.334.249.724.249 1.17 0 .45-.086.84-.257 1.169a1.82 1.82 0 01-.716.761 2.096 2.096 0 01-1.073.267h-1.386V9.665h1.092a.864.864 0 00.438-.104.706.706 0 00.28-.304c.067-.13.1-.285.1-.463a.982.982 0 00-.1-.46.672.672 0 00-.28-.295.864.864 0 00-.438-.105h-.514v4.923h-1.459z'></path>
      </g>
      <defs>
        <clipPath id='clip0_3506_2228'>
          <path fill='currentColor' d='M0 0H20V20H0z'></path>
        </clipPath>
      </defs>
    </svg>
  );
};

function HelpQuestionIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='28'
      fill='none'
      viewBox='0 0 26 28'
    >
      <path
        fill='#fff'
        d='M13 .006a12.466 12.466 0 00-9.016 3.488C1.548 5.83.115 9.06 0 12.474c-.002 2.051.52 4.067 1.514 5.847.284.503.367 1.099.233 1.663L.315 25.857a1.785 1.785 0 00.242 1.445c.207.295.502.513.84.621.338.109.701.102 1.035-.019l7.635-2.76a1.65 1.65 0 00.931-.856c.188-.401.211-.861.067-1.28a1.661 1.661 0 00-.837-.953 1.602 1.602 0 00-1.25-.068l-3.81 1.378a.534.534 0 01-.549-.114.56.56 0 01-.162-.548l.87-3.568a1.745 1.745 0 00-.308-1.448 8.669 8.669 0 01-1.746-5.212 9.669 9.669 0 013.025-6.612 9.234 9.234 0 016.703-2.508 9.234 9.234 0 016.702 2.507 9.668 9.668 0 013.026 6.613 9.262 9.262 0 01-1.752 5.242 8.927 8.927 0 01-4.393 3.239 9.134 9.134 0 01-1.432.398c-.22.043-.43.13-.616.258a1.704 1.704 0 00-.47.482 1.747 1.747 0 00-.242 1.312c.1.416.35.776.702 1.008.352.232.777.317 1.187.238a12.347 12.347 0 001.99-.55 12.175 12.175 0 005.96-4.46A12.628 12.628 0 0026 12.475c-.115-3.415-1.547-6.644-3.984-8.981A12.466 12.466 0 0013 .006z'
      ></path>
      <path
        fill='#fff'
        d='M11.411 14.993v-.25c0-.574.047-1.032.142-1.373.095-.34.236-.613.422-.819.187-.209.415-.396.685-.563.234-.146.442-.287.625-.423.186-.136.332-.28.438-.433a.874.874 0 00.165-.522.812.812 0 00-.132-.46.882.882 0 00-.356-.307 1.122 1.122 0 00-.499-.11c-.197 0-.38.044-.548.13a1.081 1.081 0 00-.4.36.961.961 0 00-.147.533H9c.007-.765.19-1.386.548-1.863a3.09 3.09 0 011.425-1.054 5.423 5.423 0 011.95-.339c.782 0 1.48.11 2.093.329.614.215 1.098.542 1.452.98.355.436.532.98.532 1.634 0 .421-.077.792-.23 1.112-.15.316-.36.596-.63.84-.267.24-.58.459-.937.657a3.832 3.832 0 00-.663.454 1.536 1.536 0 00-.406.527 1.664 1.664 0 00-.137.71v.25h-2.586zm1.337 3.507c-.424 0-.787-.14-1.09-.423a1.362 1.362 0 01-.444-1.038 1.33 1.33 0 01.444-1.018 1.546 1.546 0 011.09-.422c.402 0 .756.14 1.063.422.31.282.467.621.471 1.018a1.356 1.356 0 01-.225.74c-.142.22-.328.396-.558.528-.23.129-.48.193-.751.193z'
      ></path>
    </svg>
  );
}

export { QuestionIcon, HelpQuestionIcon };
